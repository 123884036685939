:root {
	--nav-height: 4rem;
	--primary-color: black;

	--white: white;
	--black: black;

	--mute-bg-color: #aaaaaa;
	--mute-text-color: #555555;
}

.header {
	background-color: var(--black);
	color: var(--white);
	display: block !important;
	font-size: 0.8rem;
}

/* Navbar */
.nav-height {
	/* height: var(--nav-height) !important; */
	background-color: var(--white) !important;
	z-index: 1039 !important;
	position: sticky !important;
	top: 0px !important;
}

.nav-category {
	background-color: rgba(255, 255, 255, 0.9) !important;
	z-index: 1039 !important;
	position: sticky !important;
	top: 6rem !important;
	width: 100%;
}

.logo {
	height: 4rem;
	width: 4rem;
}

.nav-text {
	text-decoration: none;
	color: var(--black) !important;
	font-size: 1rem;
	font-weight: bold;
	text-transform: uppercase;
	padding-top: 7px;
	padding-bottom: 5px;
	border-bottom: 2px solid white;
	cursor: pointer;
}

.link-text {
	text-decoration: none;
	color: var(--black) !important;
}

.desktop-nav {
	display: flex !important;
}

.mobile-nav {
	display: none !important;
}

.nav-menu {
	position: absolute;
	top: 3rem;
	left: 0px;
}

.space-nav-menu {
	height: 2rem;
}

.toggle-button {
	font-size: 1.5rem;
	cursor: pointer;
}

/* Navbar */

/* Sidebar */
.sidebar-menu {
	display: none;
}

.curtain {
	display: none;
}

.sidebar-text {
	font-weight: 600 !important;
	color: var(--black) !important;
	font-size: 0.9rem !important;
	text-decoration: none;
	cursor: pointer;
}

/* Sidebar */

/* Footer */
.footer-extra {
	background-color: var(--mute-bg-color);
	color: var(--white);
	font-size: 0.8rem;
}

.footer-main {
	background-color: var(--white);
	color: var(--black);
}

/* Footer */

@media (max-width: 992px) {
	.header {
		display: none !important;
	}

	.nav-height {
		top: 0 !important;
	}

	.space-nav-menu {
		height: 1rem;
	}

	.logo {
		height: 3.5rem;
		width: 3.5rem;
	}

	.sidebar-menu {
		background-color: white;
		width: 18rem;
		height: 100vh;
		justify-content: flex-start;
		position: fixed;
		top: 4rem;
		left: -100%;
		transition: 850ms;
		z-index: 1039;
		display: block;
	}

	.sidebar-menu.active {
		left: 0;
		transition: 350ms;
	}

	.curtain {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		opacity: 0;
		background-color: black;
		transition: 850ms;
		z-index: 1030;
		display: none;
	}

	.curtain.active {
		opacity: 0.5;
		transition: 350ms;
		display: block;
	}
}

@media (max-width: 768px) {
	.desktop-nav {
		display: none !important;
	}

	.mobile-nav {
		display: flex !important;
	}
}

/* Phone ring ring animation CSS */
.coccoc-alo-ph-circle {
	width: 160px;
	height: 160px;
	top: 20px;
	left: 20px;
	position: absolute;
	background-color: transparent;
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	border-radius: 100%;
	border: 2px solid rgba(30, 30, 30, 0.4);
	opacity: .1;
	-webkit-animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
	-moz-animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
	-ms-animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
	-o-animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
	animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
	-webkit-transition: all .5s;
	-moz-transition: all .5s;
	-o-transition: all .5s;
	transition: all .5s;
}

.coccoc-alo-phone {
	background-color: transparent;
	width: 200px;
	height: 200px;
	cursor: pointer;
	z-index: 200000 !important;
	-webkit-backface-visibility: hidden;
	-webkit-transform: translateZ(0);
	-webkit-transition: visibility .5s;
	-moz-transition: visibility .5s;
	-o-transition: visibility .5s;
	transition: visibility .5s;
	position: fixed;
	bottom: 1rem;
	right: 1rem;
}

@media (max-width: 768px) {
	.coccoc-alo-phone {
		bottom: -2rem;
		right: -2rem;
	}
}

.coccoc-alo-phone.coccoc-alo-green .coccoc-alo-ph-circle-fill {
	background-color: rgba(0, 175, 242, 0.5);
	opacity: .75 !important;
}

.coccoc-alo-ph-circle-fill {
	width: 100px;
	height: 100px;
	top: 50px;
	left: 50px;
	position: fixed;
	background-color: #000;
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	border-radius: 100%;
	border: 2px solid transparent;
	opacity: .1;
	-webkit-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
	-moz-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
	-ms-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
	-o-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
	animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
	-webkit-transition: all .5s;
	-moz-transition: all .5s;
	-o-transition: all .5s;
	transition: all .5s;
}

.coccoc-alo-ph-img-circle {
	width: 60px;
	height: 60px;
	top: 70px;
	left: 70px;
	position: absolute;
	background: rgba(30, 30, 30, 0.1) url("../../assets/icon/icon-messenger.png") no-repeat center center;
	background-color: white;
	background-size: 45px;
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	border-radius: 100%;
	border: 2px solid transparent;
	opacity: .8;
	-webkit-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
	-moz-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
	-ms-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
	-o-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
	animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
}

.coccoc-alo-phone.coccoc-alo-green .coccoc-alo-ph-circle {
	border-color: #00aff2;
	opacity: .5;
}

.coccoc-alo-phone.coccoc-alo-green.coccoc-alo-hover .coccoc-alo-ph-circle,
.coccoc-alo-phone.coccoc-alo-green:hover .coccoc-alo-ph-circle {
	border-color: #75eb50;
	opacity: .5;
}

.coccoc-alo-phone.coccoc-alo-green.coccoc-alo-hover .coccoc-alo-ph-circle-fill,
.coccoc-alo-phone.coccoc-alo-green:hover .coccoc-alo-ph-circle-fill {
	background-color: rgba(117, 235, 80, 0.5);
	opacity: .75 !important;
}

.coccoc-alo-phone.coccoc-alo-green.coccoc-alo-hover .coccoc-alo-ph-img-circle,
.coccoc-alo-phone.coccoc-alo-green:hover .coccoc-alo-ph-img-circle {
	background-color: #75eb50;
}

@-moz-keyframes coccoc-alo-circle-anim {
	0% {
		transform: rotate(0) scale(.5) skew(1deg);
		opacity: .1
	}

	30% {
		transform: rotate(0) scale(.7) skew(1deg);
		opacity: .5
	}

	100% {
		transform: rotate(0) scale(1) skew(1deg);
		opacity: .1
	}
}

@-webkit-keyframes coccoc-alo-circle-anim {
	0% {
		transform: rotate(0) scale(.5) skew(1deg);
		opacity: .1
	}

	30% {
		transform: rotate(0) scale(.7) skew(1deg);
		opacity: .5
	}

	100% {
		transform: rotate(0) scale(1) skew(1deg);
		opacity: .1
	}
}

@-o-keyframes coccoc-alo-circle-anim {
	0% {
		transform: rotate(0) scale(.5) skew(1deg);
		opacity: .1
	}

	30% {
		transform: rotate(0) scale(.7) skew(1deg);
		opacity: .5
	}

	100% {
		transform: rotate(0) scale(1) skew(1deg);
		opacity: .1
	}
}

@keyframes coccoc-alo-circle-anim {
	0% {
		transform: rotate(0) scale(.5) skew(1deg);
		opacity: .1
	}

	30% {
		transform: rotate(0) scale(.7) skew(1deg);
		opacity: .5
	}

	100% {
		transform: rotate(0) scale(1) skew(1deg);
		opacity: .1
	}
}

@-moz-keyframes coccoc-alo-circle-fill-anim {
	0% {
		transform: rotate(0) scale(.7) skew(1deg);
		opacity: .2
	}

	50% {
		transform: rotate(0) scale(1) skew(1deg);
		opacity: .2
	}

	100% {
		transform: rotate(0) scale(.7) skew(1deg);
		opacity: .2
	}
}

@-webkit-keyframes coccoc-alo-circle-fill-anim {
	0% {
		transform: rotate(0) scale(.7) skew(1deg);
		opacity: .2
	}

	50% {
		transform: rotate(0) scale(1) skew(1deg);
		opacity: .2
	}

	100% {
		transform: rotate(0) scale(.7) skew(1deg);
		opacity: .2
	}
}

@-o-keyframes coccoc-alo-circle-fill-anim {
	0% {
		transform: rotate(0) scale(.7) skew(1deg);
		opacity: .2
	}

	50% {
		transform: rotate(0) scale(1) skew(1deg);
		opacity: .2
	}

	100% {
		transform: rotate(0) scale(.7) skew(1deg);
		opacity: .2
	}
}

@keyframes coccoc-alo-circle-fill-anim {
	0% {
		transform: rotate(0) scale(.7) skew(1deg);
		opacity: .2
	}

	50% {
		transform: rotate(0) scale(1) skew(1deg);
		opacity: .2
	}

	100% {
		transform: rotate(0) scale(.7) skew(1deg);
		opacity: .2
	}
}

@-moz-keyframes coccoc-alo-circle-img-anim {
	0% {
		transform: rotate(0) scale(1) skew(1deg)
	}

	10% {
		transform: rotate(-25deg) scale(1) skew(1deg)
	}

	20% {
		transform: rotate(25deg) scale(1) skew(1deg)
	}

	30% {
		transform: rotate(-25deg) scale(1) skew(1deg)
	}

	40% {
		transform: rotate(25deg) scale(1) skew(1deg)
	}

	50% {
		transform: rotate(0) scale(1) skew(1deg)
	}

	100% {
		transform: rotate(0) scale(1) skew(1deg)
	}
}

@-webkit-keyframes coccoc-alo-circle-img-anim {
	0% {
		transform: rotate(0) scale(1) skew(1deg)
	}

	10% {
		transform: rotate(-25deg) scale(1) skew(1deg)
	}

	20% {
		transform: rotate(25deg) scale(1) skew(1deg)
	}

	30% {
		transform: rotate(-25deg) scale(1) skew(1deg)
	}

	40% {
		transform: rotate(25deg) scale(1) skew(1deg)
	}

	50% {
		transform: rotate(0) scale(1) skew(1deg)
	}

	100% {
		transform: rotate(0) scale(1) skew(1deg)
	}
}

@-o-keyframes coccoc-alo-circle-img-anim {
	0% {
		transform: rotate(0) scale(1) skew(1deg)
	}

	10% {
		transform: rotate(-25deg) scale(1) skew(1deg)
	}

	20% {
		transform: rotate(25deg) scale(1) skew(1deg)
	}

	30% {
		transform: rotate(-25deg) scale(1) skew(1deg)
	}

	40% {
		transform: rotate(25deg) scale(1) skew(1deg)
	}

	50% {
		transform: rotate(0) scale(1) skew(1deg)
	}

	100% {
		transform: rotate(0) scale(1) skew(1deg)
	}
}

@keyframes coccoc-alo-circle-img-anim {
	0% {
		transform: rotate(0) scale(1) skew(1deg)
	}

	10% {
		transform: rotate(-25deg) scale(1) skew(1deg)
	}

	20% {
		transform: rotate(25deg) scale(1) skew(1deg)
	}

	30% {
		transform: rotate(-25deg) scale(1) skew(1deg)
	}

	40% {
		transform: rotate(25deg) scale(1) skew(1deg)
	}

	50% {
		transform: rotate(0) scale(1) skew(1deg)
	}

	100% {
		transform: rotate(0) scale(1) skew(1deg)
	}
}

/* Phone ring ring animation CSS */