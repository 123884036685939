.product-item {
	cursor: pointer;
	background-color: var(--white);
	color: var(--black);
}

.product-item:hover {
	text-decoration: underline;
}

.product-no {
	position: absolute;
	top: 0;
	left: 0;
	color: var(--white);
	width: 30px;
	height: 35px;
	font-size: 1.2rem;
}

.product-title {
	font-weight: 600;
	color: var(--mute-text-color);
}

.product-price {
	font-weight: bold;
	color: var(--mute-text-color);
}

.product-image-swap {
	opacity: 1;
}

.product-image-swap:hover {
	opacity: 0;
}

.showNotifi:hover .frame-Notifi {
	visibility: visible;
	opacity: 1;
}

.frame-Notifi {
	padding: 0 2em 0 0;
	right: 0;
	visibility: hidden;
	opacity: 0;
	transition: all 0.3s ease-in-out;
}

.body-Notifi {
	background-color: white;
	width: 20rem;
	height: 22rem;
	overflow-y: scroll;
}

.shadow-app {
	box-shadow: 0 3.5px 6px rgba(0, 0, 0, 0.16) !important;
}

.navbarSelect-Color {
	background-color: white !important;
	color: black !important;
	text-decoration: none !important;
}

.navbarSelect-Color:hover {
	background-color: black !important;
	color: white !important;
	border-radius: 8px !important;
}
