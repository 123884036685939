.form-check-input:checked {
	background-color: black !important;
	border-color: black !important;
}

.form-check-input {
	border: 1px solid black !important;
}

.title {
	font-size: 2rem;
	color: var(--black);
	font-weight: 600;
}

.product-curtain {
	height: 100%;
	width: 100%;
	opacity: 0;
	cursor: pointer;
}

.product-curtain:hover {
	opacity: 1;
	background-color: rgba(0, 0, 0, 0.5);
}

.product-curtain-button {
	border: 2px solid var(--white) !important;
	color: var(--white) !important;
	font-weight: bold !important;
	background: none !important;
	border-radius: 0px !important;
}

.mlb-button {
	color: var(--white) !important;
	font-weight: bold !important;
	background-color: var(--black) !important;
	border: none !important;
	border-radius: 0px !important;
	font-size: 1rem !important;
}

.mlb-button:hover {
	background-color: rgba(0, 0, 0, 0.75) !important;
}

.category-curtain {
	height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	color: var(--white);
	cursor: pointer;
}

.category-curtain:hover {
	text-decoration: underline;
}

.category-title-home {
	font-size: 2rem;
	color: var(--white);
	font-weight: bold;
}

.cart-progress-bar {
	height: 4rem !important;
	font-size: 1.2rem !important;
	font-weight: bold !important;
	border-radius: 0 !important;
	background-color: var(--black);
	color: var(--white) !important;
}

.cart-progress-item {
	background: var(--black) !important;
	color: rgba(255, 255, 255, 0.3) !important;
}

.cart-progress-item-active {
	background: var(--black) !important;
	color: var(--white) !important;
}

.order-his-info {
	width: 6rem !important;
	font-weight: bold;
}

@media (max-width: 992px) {
	.category-title-home {
		font-size: 1.2rem;
	}

	.mlb-button {
		font-size: 0.8rem !important;
	}
}

@media (max-width: 768px) {
	.title {
		font-size: 1.5rem;
	}
}
