a {
	text-decoration: none !important;
}

.app {
	font-family: "DM Sans", "Noto Sans KR", sans-serif;
}

.image-default {
	width: 100%;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

.text-overflow-1-line {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}

.text-overflow-2-line {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.text-overflow-3-line {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

.text-overflow-4-line {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
}

/* Boostrap Pagination */
.page-item .page-link {
	color: var(--primary-color);
}

.page-item .page-link:hover {
	color: var(--primary-color);
}

.page-item.active .page-link {
	background: var(--primary-color);
	border: 1px solid var(--primary-color);
}

.page-item.active .page-link:hover {
	color: white;
}

/* Boostrap Pagination */

.dropdown-menu {
	--bs-dropdown-link-active-bg: black !important;
	margin-top: -0.1rem !important;
	border-radius: 0 !important;
	padding-top: 0.5rem !important;
	padding-bottom: 0.5rem !important;
}

.dropdown-toggle::after {
	display: none !important;
}